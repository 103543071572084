<template>
  <v-container fluid class="grey lighten-2 flexContainer">
    <v-card class="w-full h-full d-flex flex-column">
      <v-card-title class="pa-1 pl-2 secondary">
        <slot name="title">
          <v-btn
            x-small
            fab
            dark
            rounded
            @click="getStatistics"
            :disabled="!valid"
            color="secondarytext"
            elevation="1"
            class="mr-2 mt-1 mb-1"
          >
            <v-icon class="secondary--text">mdi-autorenew</v-icon>
          </v-btn>
          <span class="font-weight-medium text-h6 secondarytext--text">
            {{ $t('Navigation.admin_children.' + $route.name) }}
          </span>
        </slot>
      </v-card-title>
      <v-divider></v-divider>
      <v-row style="flex: initial; flex-shrink: 0" class="ma-0 pt-0 pb-0">
        <div class="white pb-4 pa-2 pl-4 pr-4 filterRow" style="z-index: 12">
          <div class="filters">
            <v-select
              hide-details
              v-model="docType"
              :debounce-search="0"
              :clearable="false"
              :items="documentTypes"
              v-bind:label="$t('Statistics.docType')"
              item-text="text"
              item-value="value"
              class="mr-2 text-caption"
            ></v-select>
            <v-select
              hide-details
              v-model="periodicity"
              :debounce-search="0"
              :clearable="false"
              :items="periodicityValues"
              v-bind:label="$t('Statistics.periodicity')"
              @change="periodicityChange"
              item-text="display"
              item-value="value"
              class="text-caption mr-2"
            ></v-select>
            <v-menu
              v-model="selectPeriod"
              ref="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  :value="formattedDate"
                  data-type="date"
                  class="text-caption mr-2"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :clearable="false"
                  v-bind:label="$t('Statistics.period')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="period"
                :type="getType"
                ref="datePicker"
                no-title
                :max="getMaxDate()"
                @click:year="yearSelect"
                @input="selectPeriod = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <v-btn
            required
            :disabled="!valid"
            :loading="loading"
            @click="getStatistics"
          >
            <v-icon left color="primary">mdi-cogs</v-icon>
            {{ $t('Statistics.generate') }}
          </v-btn>
        </div>
      </v-row>
      <v-row class="ma-0 pb-0 pt-0" style="overflow: auto">
        <v-col cols="12" class="fill-height white">
          <v-tabs v-model="tab">
            <v-tab>
              {{ $t('Statistics.consult') }}
            </v-tab>
            <v-tab>
              {{ $t('Statistics.deposit') }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row>
                <v-col cols="6">
                  <apexchart
                    type="area"
                    width="100%"
                    :options="optionsConsultQueries"
                    :series="seriesConsultQueries"
                    ref="chartConsultQueries"
                  ></apexchart>
                </v-col>
                <v-col cols="6">
                  <apexchart
                    type="area"
                    width="100%"
                    :options="optionsConsultPages"
                    :series="seriesConsultPages"
                    ref="chartConsultPages"
                  ></apexchart>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <apexchart
                    type="area"
                    width="100%"
                    :options="optionsConsultUsers"
                    :series="seriesConsultUsers"
                    ref="chartConsultUsers"
                  ></apexchart>
                </v-col>
                <v-col cols="6">
                  <apexchart
                    type="area"
                    width="100%"
                    :options="optionsConsultSize"
                    :series="seriesConsultSize"
                    ref="chartConsultSize"
                  ></apexchart>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col cols="6">
                  <apexchart
                    type="area"
                    width="100%"
                    :options="optionsDepositFiles"
                    :series="seriesDepositFiles"
                    ref="chartDepositFiles"
                  ></apexchart>
                </v-col>
                <v-col cols="6">
                  <apexchart
                    type="area"
                    width="100%"
                    :options="optionsDepositDocuments"
                    :series="seriesDepositDocuments"
                    ref="chartDepositDocuments"
                  ></apexchart>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <apexchart
                    type="area"
                    width="100%"
                    :options="optionsDepositPages"
                    :series="seriesDepositPages"
                    ref="chartDepositPages"
                  ></apexchart>
                </v-col>
                <v-col cols="6">
                  <apexchart
                    type="area"
                    width="100%"
                    :options="optionsDepositSize"
                    :series="seriesDepositSize"
                    ref="chartDepositSize"
                  ></apexchart>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
      transition="fade-transition"
    >
      <v-card color="primary" dark>
        <v-card-title
          class="text-center primarytext--text"
          style="display: block"
        >
          {{ $t('Global.loading') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import moment from 'moment'
import StatisticsService from '../services/StatisticsService'

export default {
  data() {
    return {
      loading: false,
      tab: null,
      init: true,
      selectDocType: false,
      selectPeriodicity: false,
      selectPeriod: false,
      docType: '',
      documentTypes: [],
      periodicity: 'month',
      period: moment().format('YYYY-MM'),
      deposit_files_data: [],
      deposit_documents_data: [],
      deposit_pages_data: [],
      deposit_size_data: [],
      consult_size_data: [],
      consult_queries_data: [],
      consult_pages_data: [],
      consult_users_data: [],
      locales: [
        {
          name: 'en',
          options: {
            months: [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ],
            shortMonths: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            days: [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            toolbar: {
              exportToSVG: 'Download SVG',
              exportToPNG: 'Download PNG',
              exportToCSV: 'Download CSV',
              menu: 'Menu',
              selection: 'Selection',
              selectionZoom: 'Selection Zoom',
              zoomIn: 'Zoom In',
              zoomOut: 'Zoom Out',
              pan: 'Panning',
              reset: 'Reset Zoom',
            },
          },
        },
        {
          name: 'fr',
          options: {
            months: [
              'janvier',
              'février',
              'mars',
              'avril',
              'mai',
              'juin',
              'juillet',
              'août',
              'septembre',
              'octobre',
              'novembre',
              'décembre',
            ],
            shortMonths: [
              'janv.',
              'févr.',
              'mars',
              'avr.',
              'mai',
              'juin',
              'juill.',
              'août',
              'sept.',
              'oct.',
              'nov.',
              'déc.',
            ],
            days: [
              'dimanche',
              'lundi',
              'mardi',
              'mercredi',
              'jeudi',
              'vendredi',
              'samedi',
            ],
            shortDays: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
            toolbar: {
              exportToSVG: 'Télécharger au format SVG',
              exportToPNG: 'Télécharger au format PNG',
              exportToCSV: 'Télécharger au format CSV',
              menu: 'Menu',
              selection: 'Sélection',
              selectionZoom: 'Sélection et zoom',
              zoomIn: 'Zoomer',
              zoomOut: 'Dézoomer',
              pan: 'Navigation',
              reset: 'Réinitialiser le zoom',
            },
          },
        },
        {
          name: 'nl',
          options: {
            months: [
              'Januari',
              'Februari',
              'Maart',
              'April',
              'Mei',
              'Juni',
              'Juli',
              'Augustus',
              'September',
              'Oktober',
              'November',
              'December',
            ],
            shortMonths: [
              'Jan',
              'Feb',
              'Mrt',
              'Apr',
              'Mei',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Okt',
              'Nov',
              'Dec',
            ],
            days: [
              'Zondag',
              'Maandag',
              'Dinsdag',
              'Woensdag',
              'Donderdag',
              'Vrijdag',
              'Zaterdag',
            ],
            shortDays: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
            toolbar: {
              exportToSVG: 'Download SVG',
              exportToPNG: 'Download PNG',
              exportToCSV: 'Download CSV',
              menu: 'Menu',
              selection: 'Selectie',
              selectionZoom: 'Zoom selectie',
              zoomIn: 'Zoom in',
              zoomOut: 'Zoom out',
              pan: 'Verplaatsen',
              reset: 'Standaardwaarden',
            },
          },
        },
      ],
    }
  },
  computed: {
    seriesDepositFiles() {
      return [
        {
          data: this.deposit_files_data,
          name: this.$t('Statistics.files'),
        },
      ]
    },
    optionsDepositFiles() {
      return {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        colors: [this.$store.getters.siteConfig.COLOR_SECONDARY_BG],
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: this.$t('Statistics.files'),
          style: {
            fontFamily: '"Roboto", sans-serif',
          },
        },
        chart: {
          locales: this.locales,
          toolbar: {
            tools: {
              pan: false,
            },
            export: {
              csv: {
                columnDelimiter: ';',
                filename:
                  'deposit_files_' + this.docType + '_' + this.formattedDateCsv,
                headerCategory: this.translatedCsvHeaderValue,
                headerValue: this.$t('Statistics.files'),
              },
              png: {
                filename:
                  'deposit_files_' + this.docType + '_' + this.formattedDateCsv,
              },
              svg: {
                filename:
                  'deposit_files_' + this.docType + '_' + this.formattedDateCsv,
              },
            },
          },
        },
      }
    },
    seriesDepositDocuments() {
      return [
        {
          data: this.deposit_documents_data,
          name: this.$t('Statistics.documents'),
        },
      ]
    },
    optionsDepositDocuments() {
      return {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        colors: [this.$store.getters.siteConfig.COLOR_SECONDARY_BG],
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: this.$t('Statistics.documents'),
          style: {
            fontFamily: '"Roboto", sans-serif',
          },
        },
        chart: {
          locales: this.locales,
          toolbar: {
            tools: {
              pan: false,
            },
            export: {
              csv: {
                columnDelimiter: ';',
                filename:
                  'deposit_documents_' +
                  this.docType +
                  '_' +
                  this.formattedDateCsv,
                headerCategory: this.translatedCsvHeaderValue,
                headerValue: this.$t('Statistics.documents'),
              },
              png: {
                filename:
                  'deposit_documents_' +
                  this.docType +
                  '_' +
                  this.formattedDateCsv,
              },
              svg: {
                filename:
                  'deposit_documents_' +
                  this.docType +
                  '_' +
                  this.formattedDateCsv,
              },
            },
          },
        },
      }
    },
    seriesDepositPages() {
      return [
        {
          data: this.deposit_pages_data,
          name: this.$t('Statistics.pages'),
        },
      ]
    },
    optionsDepositPages() {
      return {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        colors: [this.$store.getters.siteConfig.COLOR_SECONDARY_BG],
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: this.$t('Statistics.pages'),
          style: {
            fontFamily: '"Roboto", sans-serif',
          },
        },
        chart: {
          locales: this.locales,
          toolbar: {
            tools: {
              pan: false,
            },
            export: {
              csv: {
                columnDelimiter: ';',
                filename:
                  'deposit_pages_' + this.docType + '_' + this.formattedDateCsv,
                headerCategory: this.translatedCsvHeaderValue,
                headerValue: this.$t('Statistics.pages'),
              },
              png: {
                filename:
                  'deposit_pages_' + this.docType + '_' + this.formattedDateCsv,
              },
              svg: {
                filename:
                  'deposit_pages_' + this.docType + '_' + this.formattedDateCsv,
              },
            },
          },
        },
      }
    },
    seriesDepositSize() {
      return [
        {
          data: this.deposit_size_data,
          name: this.$t('Statistics.size'),
        },
      ]
    },
    optionsDepositSize() {
      return {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        colors: [this.$store.getters.siteConfig.COLOR_SECONDARY_BG],
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: this.$t('Statistics.size'),
          style: {
            fontFamily: '"Roboto", sans-serif',
          },
        },
        chart: {
          locales: this.locales,
          toolbar: {
            tools: {
              pan: false,
            },
            export: {
              csv: {
                columnDelimiter: ';',
                filename:
                  'deposit_size_' + this.docType + '_' + this.formattedDateCsv,
                headerCategory: this.translatedCsvHeaderValue,
                headerValue: this.$t('Statistics.downloads'),
              },
              png: {
                filename:
                  'deposit_size_' + this.docType + '_' + this.formattedDateCsv,
              },
              svg: {
                filename:
                  'deposit_size_' + this.docType + '_' + this.formattedDateCsv,
              },
            },
          },
        },
      }
    },
    seriesConsultPages() {
      return [
        {
          data: this.consult_pages_data,
          name: this.$t('Statistics.downloads'),
        },
      ]
    },
    optionsConsultPages() {
      return {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        colors: [this.$store.getters.siteConfig.COLOR_SECONDARY_BG],
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: this.$t('Statistics.downloads'),
          style: {
            fontFamily: '"Roboto", sans-serif',
          },
        },
        chart: {
          locales: this.locales,
          toolbar: {
            tools: {
              pan: false,
            },
            export: {
              csv: {
                columnDelimiter: ';',
                filename:
                  'consult_pages_' + this.docType + '_' + this.formattedDateCsv,
                headerCategory: this.translatedCsvHeaderValue,
                headerValue: this.$t('Statistics.downloads'),
              },
              png: {
                filename:
                  'consult_pages_' + this.docType + '_' + this.formattedDateCsv,
              },
              svg: {
                filename:
                  'consult_pages_' + this.docType + '_' + this.formattedDateCsv,
              },
            },
          },
        },
      }
    },
    seriesConsultQueries() {
      return [
        {
          data: this.consult_queries_data,
          name: this.$t('Statistics.queries'),
        },
      ]
    },
    optionsConsultQueries() {
      return {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        colors: [this.$store.getters.siteConfig.COLOR_SECONDARY_BG],
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: this.$t('Statistics.queries'),
          style: {
            fontFamily: '"Roboto", sans-serif',
          },
        },
        chart: {
          locales: this.locales,
          toolbar: {
            tools: {
              pan: false,
            },
            export: {
              csv: {
                columnDelimiter: ';',
                filename:
                  'consult_queries_' +
                  this.docType +
                  '_' +
                  this.formattedDateCsv,
                headerCategory: this.translatedCsvHeaderValue,
                headerValue: this.$t('Statistics.queries'),
              },
              png: {
                filename:
                  'consult_queries_' +
                  this.docType +
                  '_' +
                  this.formattedDateCsv,
              },
              svg: {
                filename:
                  'consult_queries_' +
                  this.docType +
                  '_' +
                  this.formattedDateCsv,
              },
            },
          },
        },
      }
    },
    seriesConsultUsers() {
      return [
        {
          data: this.consult_users_data,
          name: this.$t('Statistics.uniqueUsers'),
        },
      ]
    },
    optionsConsultUsers() {
      return {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        colors: [this.$store.getters.siteConfig.COLOR_SECONDARY_BG],
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: this.$t('Statistics.uniqueUsers'),
          style: {
            fontFamily: '"Roboto", sans-serif',
          },
        },
        chart: {
          locales: this.locales,
          toolbar: {
            tools: {
              pan: false,
            },
            export: {
              csv: {
                columnDelimiter: ';',
                filename:
                  'consult_users_' + this.docType + '_' + this.formattedDateCsv,
                headerCategory: this.translatedCsvHeaderValue,
                headerValue: this.$t('Statistics.uniqueUsers'),
              },
              png: {
                filename:
                  'consult_users_' + this.docType + '_' + this.formattedDateCsv,
              },
              svg: {
                filename:
                  'consult_users_' + this.docType + '_' + this.formattedDateCsv,
              },
            },
          },
        },
      }
    },
    seriesConsultSize() {
      return [
        {
          data: this.consult_size_data,
          name: this.$t('Statistics.downloadSize'),
        },
      ]
    },
    optionsConsultSize() {
      return {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        colors: [this.$store.getters.siteConfig.COLOR_SECONDARY_BG],
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: this.$t('Statistics.downloadSize'),
          style: {
            fontFamily: '"Roboto", sans-serif',
          },
        },
        chart: {
          locales: this.locales,
          toolbar: {
            tools: {
              pan: false,
            },
            export: {
              csv: {
                columnDelimiter: ';',
                filename:
                  'consult_size_' + this.docType + '_' + this.formattedDateCsv,
                headerCategory: this.translatedCsvHeaderValue,
                headerValue: this.$t('Statistics.downloadSize'),
              },
              png: {
                filename:
                  'consult_size_' + this.docType + '_' + this.formattedDateCsv,
              },
              svg: {
                filename:
                  'consult_size_' + this.docType + '_' + this.formattedDateCsv,
              },
            },
          },
        },
      }
    },
    formattedDate() {
      if (this.period == '') {
        return ''
      } else {
        switch (this.periodicity) {
          case 'day':
            return moment(this.period).format('DD/MM/YYYY')
          case 'month':
            return moment(this.period).format('MM/YYYY')
          case 'year':
            return moment(this.period).format('YYYY')
          default:
            return ''
        }
      }
    },
    formattedDateCsv() {
      if (this.period == '') {
        return ''
      } else {
        switch (this.periodicity) {
          case 'day':
            return moment(this.period).format('DD-MM-YYYY')
          case 'month':
            return moment(this.period).format('MM-YYYY')
          case 'year':
            return moment(this.period).format('YYYY')
          default:
            return ''
        }
      }
    },
    getType() {
      switch (this.periodicity) {
        case 'day':
          return 'date'
        default:
          return 'month'
      }
    },
    translatedPeriodicityValue() {
      let value = ''

      switch (this.periodicity) {
        case 'day':
          value = this.$t('Statistics.day')
          break
        case 'month':
          value = this.$t('Statistics.month')
          break
        case 'year':
          value = this.$t('Statistics.year')
          break
      }

      return value
    },
    translatedCsvHeaderValue() {
      let value = ''

      switch (this.periodicity) {
        case 'day':
          value = this.$t('Statistics.hour')
          break
        case 'month':
          value = this.$t('Statistics.day')
          break
        case 'year':
          value = this.$t('Statistics.month')
          break
      }

      return value
    },
    periodicityValues() {
      let array = []

      array.push({
        display: this.$t('Statistics.year'),
        value: 'year',
      })

      array.push({
        display: this.$t('Statistics.month'),
        value: 'month',
      })

      array.push({
        display: this.$t('Statistics.day'),
        value: 'day',
      })

      return array
    },
    valid() {
      return this.periodicity != '' && this.period != '' && this.docType != ''
    },
  },
  watch: {
    selectPeriod(val) {
      if (val && this.periodicity == 'year') {
        this.$nextTick(() => (this.$refs.datePicker.activePicker = 'YEAR'))
      }
    },
    '$i18n.locale': function () {
      this.setChartLocales()
    },
  },
  created() {
    this.getDocumentTypes()
  },
  methods: {
    setChartLocales() {
      if (this.$refs.chartConsultQueries != null) {
        this.$refs.chartConsultQueries.setLocale(this.$i18n.locale)
      }
      if (this.$refs.chartConsultPages != null) {
        this.$refs.chartConsultPages.setLocale(this.$i18n.locale)
      }
      if (this.$refs.chartConsultUsers != null) {
        this.$refs.chartConsultUsers.setLocale(this.$i18n.locale)
      }
      if (this.$refs.chartConsultSize != null) {
        this.$refs.chartConsultSize.setLocale(this.$i18n.locale)
      }

      if (this.$refs.chartDepositFiles != null) {
        this.$refs.chartDepositFiles.setLocale(this.$i18n.locale)
      }
      if (this.$refs.chartDepositDocuments != null) {
        this.$refs.chartDepositDocuments.setLocale(this.$i18n.locale)
      }
      if (this.$refs.chartDepositPages != null) {
        this.$refs.chartDepositPages.setLocale(this.$i18n.locale)
      }
      if (this.$refs.chartDepositSize != null) {
        this.$refs.chartDepositSize.setLocale(this.$i18n.locale)
      }
    },
    getDocumentTypes() {
      let inst = this
      inst.loading = true
      StatisticsService.getDocumentTypes()
        .then((resp) => {
          inst.documentTypes = resp.data
          inst.documentTypes = inst.documentTypes.sort()
          if (inst.documentTypes.length > 0) {
            inst.docType = inst.documentTypes[0]
            inst.getStatistics()
            if (inst.init) {
              inst.setChartLocales()
              inst.init = false
            }
          }
        })
        .finally(function () {
          inst.loading = false
        })
    },
    yearSelect(year) {
      if (this.periodicity == 'year') {
        this.period = year.toString()
        this.$refs.menu.save(year)
        if (this.$refs.datePicker != null) {
          this.$refs.datePicker.activePicker = 'YEAR'
        }
        this.selectPeriod = false
      }
    },
    getMaxDate() {
      switch (this.periodicity) {
        case 'day':
          return moment().format('YYYY-MM-DD')
        case 'month':
          return moment().format('YYYY-MM')
        default:
          return moment().format('YYYY')
      }
    },
    periodicityChange() {
      if (this.periodicity == 'year') {
        if (this.$refs.datePicker != null) {
          this.$refs.datePicker.activePicker = 'YEAR'
        }
        this.period = moment().format('YYYY')
      }
      if (this.periodicity == 'month') {
        this.period = moment().format('YYYY-MM')
      }
      if (this.periodicity == 'day') {
        this.period = moment().format('YYYY-MM-DD')
      }
    },
    getStatistics: function () {
      let inst = this
      if (inst.valid) {
        inst.loading = true
        let request = {}

        request.DomainName = this.$store.getters.siteConfig.DOMAIN
        request.DocumentType = this.docType
        request.Periodicity = this.periodicity
        request.Period = this.period
        request.Deposits = ['1', '2', '3', '4']
        request.Consults = ['1', '2', '3', '4']

        switch (this.periodicity) {
          case 'day':
            request.Periodicity = 'd/m/Y'
            request.Period =
              this.period.split('-')[2] +
              '/' +
              this.period.split('-')[1] +
              '/' +
              this.period.split('-')[0]
            break
          case 'month':
            request.Periodicity = 'm/Y'
            request.Period =
              this.period.split('-')[1] + '/' + this.period.split('-')[0]
            break
          case 'year':
            request.Periodicity = 'Y'
            request.Period = this.period.split('-')[0]
            break
        }

        StatisticsService.getStatistics(request)
          .then((resp) => {
            inst.deposit_files_data = []
            inst.deposit_documents_data = []
            inst.deposit_pages_data = []
            inst.deposit_size_data = []

            inst.consult_users_data = []
            inst.consult_queries_data = []
            inst.consult_pages_data = []
            inst.consult_size_data = []

            if (resp.data.DepositTotalsEas) {
              resp.data.DepositTotalsEas.forEach((element) => {
                inst.deposit_files_data.push(element.NumberOfDeposits)

                inst.deposit_documents_data.push(
                  element.NumberOfDocumentsInDeposit
                )

                inst.deposit_pages_data.push(element.NumberOfPagesInDeposit)

                inst.deposit_size_data.push(
                  (element.DepositsSizeInKBytes / 1024).toFixed(2)
                )
              })
            }

            if (resp.data.ConsultTotalsEas) {
              resp.data.ConsultTotalsEas.forEach((element) => {
                inst.consult_users_data.push(element.UniqueUsers)

                inst.consult_queries_data.push(element.NumberOfQueries)

                inst.consult_pages_data.push(element.NumberOfPagesDownloaded)

                inst.consult_size_data.push(
                  (element.TotalDownloadSizeInBytes / 1024 / 1024).toFixed(2)
                )
              })
            }
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
  },
}
</script>
