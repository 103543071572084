<template>
  <v-container fluid class="grey lighten-2 flexContainer">
    <v-card class="w-full h-full d-flex flex-column">
      <v-card-title class="pa-1 pl-2 secondary">
        <slot name="title">
          <v-btn
            x-small
            fab
            dark
            rounded
            @click="getConstraints"
            :disabled="!valid"
            color="secondarytext"
            elevation="1"
            class="mr-2 mt-1 mb-1"
          >
            <v-icon class="secondary--text">mdi-autorenew</v-icon>
          </v-btn>
          <span class="font-weight-medium text-h6 secondarytext--text">
            {{ $t('Navigation.admin_children.' + $route.name) }}
          </span>
        </slot>
      </v-card-title>
      <v-divider></v-divider>
      <v-row style="flex: initial; flex-shrink: 0" class="ma-0 pt-0 pb-0">
        <div class="white pb-4 pa-2 pl-4 pr-4 filterRow">
          <div class="filters">
            <v-select
              hide-details
              v-model="documentType"
              :debounce-search="0"
              :clearable="false"
              :items="documentTypes"
              @change="onDocTypeChange"
              v-bind:label="$t('AuthorizationConstraints.docType')"
              class="text-caption mr-2"
            ></v-select>
            <v-select
              hide-details
              v-model="index"
              :debounce-search="0"
              :clearable="false"
              :items="indexes"
              @change="getConstraints"
              v-bind:label="$t('AuthorizationConstraints.index')"
              item-text="Name"
              item-value="Name"
              class="text-caption mr-2"
            >
              <template slot="item" slot-scope="{ item }">
                <span v-if="item.Hasconstraints"
                  >{{ item.Name }}
                  <v-icon class="ml-2" small>mdi-shield-lock</v-icon></span
                >
                <span v-else>{{ item.Name }}</span>
              </template>
              <template slot="selection" slot-scope="{ item }">
                <span v-if="item.Hasconstraints"
                  >{{ item.Name }}
                  <v-icon class="ml-2" small>mdi-shield-lock</v-icon></span
                >
                <span v-else>{{ item.Name }}</span>
              </template>
            </v-select>
          </div>
          <v-switch
            v-model="maintenanceMode"
            hide-details
            class="mr-2 pb-1"
            :loading="loading || waitingForUnlock"
            :disabled="!maintenanceModeValid || loading || waitingForUnlock"
            @change="switchLockState"
            inset
            v-bind:label="$t('AuthorizationConstraints.maintenanceMode')"
          ></v-switch>
          <v-btn
            :loading="loading"
            :disabled="!valid || !maintenanceMode"
            @click="showAddConstraintDialog"
          >
            <v-icon left color="primary">mdi-plus</v-icon>
            {{ $t('AuthorizationConstraints.addConstraint') }}
          </v-btn>
        </div>
      </v-row>
      <v-row class="ma-0 pb-0 pt-0" style="overflow: auto">
        <argus-grid
          ref="argusGrid"
          v-bind:items="items"
          v-bind:totalResults="totalResults"
          v-bind:headers="headers"
          v-bind:hideFooter="true"
          v-bind:loadData="getConstraints"
        >
          <template slot="renderer.Info" slot-scope="{ item }">
            <v-btn
              color="primary"
              @click="showEditConstraintDialog(item)"
              icon
              :disabled="!maintenanceMode"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              @click="deleteConstraint(item)"
              icon
              :disabled="!maintenanceMode"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </argus-grid>
      </v-row>
    </v-card>
    <v-dialog
      v-model="addConstraintDialog"
      width="750"
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title
            v-if="!editConstraint"
            dark
            color="primary"
            class="text-center primarytext--text"
            style="display: block"
          >
            {{ $t('AuthorizationConstraints.addConstraint') }}
          </v-toolbar-title>
          <v-toolbar-title
            v-if="editConstraint"
            dark
            color="primary"
            class="text-center"
            style="display: block"
          >
            {{ $t('AuthorizationConstraints.editConstraint') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="addConstraintDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4 fixedHeight pb-0">
          <h3>{{ $t('AuthorizationConstraints.name') }}</h3>

          <v-text-field
            v-model="newConstraint.Name"
            hide-details
            dense
            :clearable="false"
            class="text-caption"
          >
          </v-text-field>

          <v-divider class="mt-4 mb-4"></v-divider>

          <h3>{{ $t('AuthorizationConstraints.values') }}</h3>

          <div
            v-for="i in newConstraint.ConstraintValues"
            :key="i.Guid"
            class="mt-4"
          >
            <v-row>
              <v-col class="pt-1 pb-1">
                <v-text-field
                  v-model="i.Filter"
                  hide-details
                  dense
                  v-bind:label="$t('AuthorizationConstraints.filter')"
                  class="text-caption"
                >
                </v-text-field>
              </v-col>
              <v-col class="pt-1 pb-1">
                <v-text-field
                  v-model="i.Display"
                  hide-details
                  dense
                  v-bind:label="$t('AuthorizationConstraints.display')"
                  class="text-caption"
                >
                </v-text-field>
              </v-col>
              <v-col cols="1" class="pt-1 pb-1">
                <v-btn
                  @click="
                    newConstraint.ConstraintValues =
                      newConstraint.ConstraintValues.filter(
                        (q) => q.Guid != i.Guid
                      )
                  "
                  icon
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <v-btn
            @click="addNewConstraintValue"
            small
            class="mt-4"
            v-if="newConstraint.ConstraintValues != null"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t('AuthorizationConstraints.addValue') }}
          </v-btn>

          <v-divider class="mt-4 mb-4"></v-divider>

          <h3 class="mb-4">{{ $t('AuthorizationConstraints.acl') }}</h3>

          <div v-for="i in newConstraint.ConstraintACL" :key="i.Guid">
            <v-row>
              <v-col v-if="!i.IsRole">
                <v-text-field
                  v-model="i.Name"
                  hide-details
                  dense
                  readonly
                  :prepend-icon="
                    i.IsGroup ? 'mdi-account-multiple' : 'mdi-account'
                  "
                  v-bind:label="$t('AuthorizationConstraints.name')"
                  class="text-caption"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="!i.IsRole">
                <v-text-field
                  v-model="i.Issuer.IssuerName"
                  hide-details
                  dense
                  readonly
                  v-bind:label="$t('AuthorizationConstraints.issuer')"
                  class="text-caption"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="i.IsRole">
                <v-text-field
                  v-model="i.Role.RoleName"
                  hide-details
                  dense
                  readonly
                  prepend-icon="mdi-account-details"
                  v-bind:label="$t('AuthorizationConstraints.role')"
                  class="text-caption"
                >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn icon @click="showAddAclDialog(i)"
                  ><v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1">
                <v-btn
                  icon
                  @click="
                    newConstraint.ConstraintACL =
                      newConstraint.ConstraintACL.filter(
                        (q) => q.Guid != i.Guid
                      )
                  "
                  ><v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <v-btn small class="mt-4" @click="showAddAclDialog">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('AuthorizationConstraints.addACL') }}
          </v-btn>

          <v-divider class="mt-4 mb-0"></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            required
            :disabled="!addConstraintIsValid"
            :loading="loading"
            color="success"
            @click="addConstraint"
            class="mt-2 mr-3 mb-2"
          >
            <v-icon left>mdi-check</v-icon>
            {{ $t('AuthorizationConstraints.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addConstraintAclDialog"
      width="750"
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title
            dark
            color="primary"
            class="text-center primarytext--text"
            style="display: block"
          >
            {{ $t('AuthorizationConstraints.acl') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="addConstraintAclDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4 pb-0">
          <v-row>
            <v-col cols="12">
              <v-select
                :items="aclItems"
                item-text="text"
                item-value="value"
                :debounce-search="0"
                v-model="newAcl.AclType"
                v-bind:label="$t('AuthorizationConstraints.type')"
                class="text-caption mt-2"
                hide-details
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row v-if="newAcl.AclType != 'role'">
            <v-col cols="6">
              <v-select
                hide-details
                dense
                v-model="newAcl.Issuer"
                :debounce-search="0"
                :clearable="false"
                :items="issuers"
                @change="onIssuerChange(newAcl.Issuer)"
                v-bind:label="$t('AuthorizationConstraints.issuer')"
                item-text="IssuerName"
                item-value="IssuerName"
                class="text-caption"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-if="newAcl.AclType == 'user' && newAcl.Issuer != ''"
                hide-details
                dense
                v-model="newAcl.Name"
                :debounce-search="0"
                :clearable="false"
                :items="users"
                v-bind:label="$t('AuthorizationConstraints.user')"
                item-text="UserName"
                item-value="UserName"
                class="text-caption"
              ></v-select>
              <v-select
                v-if="newAcl.AclType == 'group' && newAcl.Issuer != ''"
                hide-details
                dense
                v-model="newAcl.Name"
                :debounce-search="0"
                :clearable="false"
                :items="groups"
                v-bind:label="$t('AuthorizationConstraints.group')"
                class="text-caption"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="newAcl.AclType == 'role'">
            <v-col cols="12">
              <v-select
                hide-details
                dense
                v-model="newAcl.Role"
                :debounce-search="0"
                :clearable="false"
                :items="roles"
                v-bind:label="$t('AuthorizationConstraints.role')"
                item-text="RoleName"
                item-value="RoleName"
                class="text-caption"
              ></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            :disabled="!addAclIsValid"
            color="success"
            @click="addNewConstraintACL"
            class="mt-2 mr-3 mb-2"
          >
            <v-icon left>mdi-check</v-icon>
            {{ $t('AuthorizationConstraints.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
      transition="fade-transition"
    >
      <v-card color="primary" dark>
        <v-card-title
          class="text-center primarytext--text"
          style="display: block"
        >
          {{ $t('Global.loading') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Confirm ref="confirmDelete"></Confirm>
  </v-container>
</template>

<style scoped>
.fixedHeight {
  max-height: 75vh;
  overflow: auto;
}
</style>

<script>
import AuthorizationService from '../services/AuthorizationService'

export default {
  data() {
    return {
      items: [],
      totalResults: 0,
      documentType: '',
      documentTypes: [],
      index: '',
      indexes: [],
      issuers: [],
      roles: [],
      groups: [],
      users: [],
      loading: false,
      addConstraintDialog: false,
      addConstraintAclDialog: false,
      maintenanceMode: false,
      waitingForUnlock: false,
      newConstraint: {
        Name: '',
        ConstraintValues: [],
        ConstraintACL: [],
      },
      newAcl: {
        Name: '',
        Issuer: '',
        Role: '',
        IsGroup: false,
        IsRole: true,
        AclType: 'role',
        Guid: this.generateGuid(),
      },
      editConstraint: false,
      editAcl: false,
    }
  },
  components: {
    ArgusGrid: () => import('../components/ArgusGrid'),
    Confirm: () => import('../components/Confirm'),
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('AuthorizationConstraints.name'),
          value: 'Name',
          sortable: false,
          width: '90%',
        },
        {
          text: this.$t('AuthorizationConstraints.info'),
          value: 'Info',
          sortable: false,
          renderer: true,
          align: 'center',
          width: '150px',
        },
      ]
    },
    valid() {
      return this.documentType != '' && this.index != ''
    },
    maintenanceModeValid() {
      return this.documentType != ''
    },
    addAclIsValid() {
      if (this.newAcl.AclType == 'role') {
        return this.newAcl.Role != ''
      } else {
        return this.newAcl.Name != '' && this.newAcl.Issuer != ''
      }
    },
    addConstraintIsValid() {
      return (
        this.newConstraint.ConstraintValues.length > 0 &&
        this.newConstraint.ConstraintACL.length > 0 &&
        this.newConstraint.ConstraintValues.every(
          (q) => !!q.Filter && !!q.Display
        ) &&
        this.newConstraint.Name != ''
      )
    },
    aclItems() {
      let items = []
      this.$i18n.locale

      items.push({
        text: this.$t('AuthorizationConstraints.role'),
        value: 'role',
      })

      items.push({
        text: this.$t('AuthorizationConstraints.user'),
        value: 'user',
      })

      items.push({
        text: this.$t('AuthorizationConstraints.group'),
        value: 'group',
      })

      return items
    },
  },
  methods: {
    generateGuid() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      )
    },
    getRoles() {
      return new Promise((resolve, reject) => {
        this.loading = true
        let inst = this

        if (
          this.documentType == '' ||
          this.documentType == null ||
          this.documentType == undefined
        ) {
          this.roles = []
          this.issuers = []
        } else {
          AuthorizationService.getRolesAndIssuers({
            DocumentType: this.documentType,
          })
            .then((resp) => {
              inst.roles = resp.data.Roles
              inst.issuers = resp.data.Issuers
              resolve(resp)
            })
            .catch((err) => {
              reject(err)
            })
            .finally(function () {
              inst.loading = false
            })
        }
      })
    },
    addNewConstraintValue() {
      this.newConstraint.ConstraintValues.push({
        Filter: '',
        Display: '',
        Guid: this.generateGuid(),
      })
    },
    addNewConstraintACL() {
      let acl = {
        IsRole: this.newAcl.AclType == 'role',
        Guid: this.newAcl.Guid,
      }

      if (acl.IsRole) {
        acl.Role = this.roles.filter((q) => q.RoleName == this.newAcl.Role)[0]
      } else {
        acl.IsGroup = this.newAcl.AclType == 'group'
        acl.Name = this.newAcl.Name
        acl.Issuer = this.issuers.filter(
          (q) => q.IssuerName == this.newAcl.Issuer
        )[0]
      }

      if (!this.editAcl) {
        this.newConstraint.ConstraintACL.push(acl)
      } else {
        let idx = this.newConstraint.ConstraintACL.findIndex(
          (q) => q.Guid == this.newAcl.Guid
        )
        this.newConstraint.ConstraintACL[idx] = acl
      }

      this.addConstraintAclDialog = false
    },
    addConstraint() {
      let inst = this
      let request = {
        DocumentType: this.documentType,
        Index: this.index,
        Constraint: this.newConstraint,
      }

      if (this.editConstraint) {
        AuthorizationService.updateConstraint(request)
          .then(function () {
            inst.addConstraintDialog = false
            inst.getConstraints()
          })
          .finally(function () {
            inst.loading = false
          })
      } else {
        AuthorizationService.addConstraint(request)
          .then(function () {
            inst.addConstraintDialog = false
            inst.getConstraints()
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
    deleteConstraint(item) {
      this.$refs.confirmDelete
        .open(
          this.$t('AuthorizationConstraints.deleteConstraint'),
          this.$t('Global.areYouSure'),
          {
            color: 'red',
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.loading = true
            let inst = this

            let request = {
              Index: this.index,
              DocumentType: this.documentType,
              Constraints: [item.Name],
            }

            AuthorizationService.deleteConstraint(request)
              .then(function () {
                inst.getConstraints()
              })
              .finally(function () {
                inst.loading = false
              })
          }
        })
    },
    getConstraints() {
      if (this.valid) {
        let inst = this
        let request = {}

        inst.loading = true

        request.DocumentType = this.documentType
        request.Index = this.index

        return new Promise((resolve, reject) => {
          AuthorizationService.getConstraints(request)
            .then((resp) => {
              inst.totalResults = resp.data.itemsCount
              inst.items = resp.data.data
              resolve(resp)
            })
            .catch((err) => {
              reject(err)
            })
            .finally(() => {
              inst.loading = false
            })
        })
      }
    },
    onDocTypeChange() {
      let inst = this

      if (
        this.documentType != '' &&
        this.documentType != null &&
        this.documentType != undefined
      ) {
        this.getLockState().then(() => {
          inst.getRoles().then(() => {
            inst.getIndexes().then(() => {
              if (inst.indexes.length > 0) {
                inst.index = inst.indexes[0].Name
                inst.getConstraints()
              }
            })
          })
        })
      }
    },
    onIssuerChange(issuerName) {
      return new Promise((resolve, reject) => {
        this.loading = true
        let inst = this

        let request = {
          IssuerName: issuerName,
          DocumentType: this.documentType,
          PageSize: 1000,
          PageIndex: 1,
        }

        AuthorizationService.getUsersACL(request)
          .then((resp) => {
            inst.users = resp.data.data

            AuthorizationService.getGroupsACL(request).then((resp) => {
              inst.groups = resp.data
              resolve(resp)
            })
          })
          .catch((err) => {
            reject(err)
          })
          .finally(function () {
            inst.loading = false
          })
      })
    },
    getLockState() {
      return new Promise((resolve, reject) => {
        this.loading = true
        let inst = this

        AuthorizationService.getLockState({
          DocumentType: this.documentType,
        })
          .then((resp) => {
            switch (resp.data) {
              case 0:
                inst.maintenanceMode = false
                inst.waitingForUnlock = false
                break
              case 1:
              case 4:
                inst.maintenanceMode = true
                inst.waitingForUnlock = false
                break
              case 2:
                inst.maintenanceMode = true
                inst.waitingForUnlock = true
                break
            }
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
          .finally(function () {
            inst.loading = false
          })
      })
    },
    showAddAclDialog(item) {
      if (this.editConstraint && item.Guid != null) {
        this.editAcl = true
        this.newAcl.Name = item.Name
        this.newAcl.Guid = item.Guid
        if (item.IsRole) {
          this.newAcl.Role = item.Role.RoleName
          this.newAcl.AclType = 'role'
        } else {
          this.newAcl.Issuer = item.Issuer.IssuerName
          if (item.IsGroup) {
            this.newAcl.AclType = 'group'
          } else {
            this.newAcl.AclType = 'user'
          }
        }
        this.onIssuerChange(this.newAcl.Issuer)
      } else {
        this.editAcl = false
        this.newAcl = {
          Name: '',
          Issuer: '',
          Role: '',
          AclType: 'role',
          Guid: this.generateGuid(),
        }
      }

      this.users = []
      this.groups = []

      this.addConstraintAclDialog = true
    },
    showEditConstraintDialog(item) {
      let inst = this

      this.newConstraint = {
        Name: item.Name,
        ConstraintValues: item.ConstraintValues,
        ConstraintACL: item.ConstraintACL,
      }

      this.newConstraint.ConstraintACL.forEach((element) => {
        element.Guid = inst.generateGuid()
        if (
          element.Role != '' &&
          element.Role != undefined &&
          element.Role != null
        ) {
          element.IsRole = true
        } else {
          element.IsRole = false
        }
      })

      this.newConstraint.ConstraintValues.forEach((element) => {
        element.Guid = inst.generateGuid()
      })

      this.users = []
      this.groups = []

      this.editConstraint = true

      this.addConstraintDialog = true
    },
    showAddConstraintDialog() {
      this.newConstraint = {
        Name: '',
        ConstraintValues: [],
        ConstraintACL: [],
      }

      this.users = []
      this.groups = []

      this.editConstraint = false

      this.addConstraintDialog = true
    },
    getIndexes() {
      this.loading = true
      let inst = this

      return new Promise((resolve, reject) => {
        AuthorizationService.getIndexes({ DocumentType: this.documentType })
          .then((resp) => {
            inst.indexes = resp.data

            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
          .finally(function () {
            inst.loading = false
          })
      })
    },
    getDocumentTypes() {
      this.loading = true
      let inst = this

      AuthorizationService.getDocumentTypesConstraints()
        .then((resp) => {
          inst.documentTypes = resp.data
          inst.documentTypes = inst.documentTypes.sort()
          if (inst.documentTypes.length > 0) {
            inst.documentType = inst.documentTypes[0]
            inst.onDocTypeChange()
          }
        })
        .finally(function () {
          inst.loading = false
        })
    },
    switchLockState() {
      this.loading = true
      let inst = this

      if (this.maintenanceMode) {
        AuthorizationService.enableMaintenanceMode({
          DocumentType: this.documentType,
        })
          .then(function () {
            inst.maintenanceMode = true
          })
          .finally(function () {
            inst.loading = false
          })
      } else {
        AuthorizationService.disableMaintenanceMode({
          DocumentType: this.documentType,
        })
          .then(function () {
            inst.maintenanceMode = true
            inst.waitingForUnlock = true
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
    checkLockState() {
      if (this.waitingForUnlock && this.valid) {
        this.getLockState()
      }
    },
  },
  mounted() {
    this.getDocumentTypes()

    setInterval(this.checkLockState, 10000)
  },
}
</script>
