<template>
  <eas-grid :key="$route.params.id" v-if="validDocType"> </eas-grid>
</template>

<style scoped>
.v-data-table col:first-of-type {
  width: 56px;
}

.easDropzone {
  height: 100%;
}

.dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.dropzone .dz-preview .dz-error-message {
  top: 210px;
}

.v-data-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
</style>

<script>
export default {
  computed: {
    validDocType() {
      let config = this.$store.getters.config
      let inst = this
      let found = false

      config.Groups.forEach(function (item) {
        if (item.Name == 'EAS') {
          item.ConfigItem.Config.DocumentTypes.forEach((documentType) => {
            if (documentType.docType == inst.$route.params.id) {
              found = true
            }
          })
        }
      })

      return found
    },
  },
  components: {
    EasGrid: () => import('../components/EasGrid'),
  },
  mounted() {
    if (!this.validDocType) {
      this.$router.push('/404')
    }
  },
}
</script>
