<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
    let easGroupResult = this.$store.getters.routes.filter(
      (q) => q.name == 'Navigation.eas'
    )
    if (
      easGroupResult.length == 1 &&
      easGroupResult[0].children != null &&
      Array.isArray(easGroupResult[0].children) &&
      easGroupResult[0].children.length > 0
    ) {
      easGroupResult[0].children.sort((a, b) => a.name.localeCompare(b.name))
      this.$router.push(easGroupResult[0].children[0].path)
    } else {
      this.$router.push('/home')
    }
  },
}
</script>
