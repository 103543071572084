<template>
  <div>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-title class="text-center" style="display: block">
          {{ $t('Global.loading') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    error: false,
    loading: false,
  }),
  mounted() {
    let login = this.$route.query.login
    let password = this.$route.query.password
    let inst = this

    if (
      login == null ||
      password == null ||
      login == undefined ||
      password == undefined ||
      login == '' ||
      password == ''
    ) {
      this.$toast.error(
        'Echec d’authentification. Si le problème persiste, veuillez vous adresser à l’administrateur de l’application.',
        {
          position: 'top-right',
          timeout: 10000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: 'button',
          icon: 'v-icon mdi mdi-alert-circle',
          rtl: false,
          toastClassName: 'customToast',
        }
      )
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    } else {
      this.loading = true
      this.$store
        .dispatch('login', { name: login, password: password.padEnd(10, '0') })
        .then(() => {
          inst.$store.dispatch('setRoutes', inst).then(function () {
            let easGroupResult = inst.$store.getters.routes.filter(
              (q) => q.name == 'Navigation.eas'
            )
            if (
              easGroupResult.length == 1 &&
              easGroupResult[0].children != null &&
              Array.isArray(easGroupResult[0].children) &&
              easGroupResult[0].children.length > 0
            ) {
              easGroupResult[0].children.sort((a, b) =>
                a.name.localeCompare(b.name)
              )
              inst.$router.push(easGroupResult[0].children[0].path)
            } else {
              inst.$router.push('/')
            }
          })
        })
        .finally(() => (inst.loading = false))
    }
  },
}
</script>
