<template>
  <v-container class="fill-height login-container" fluid>
    <v-row align="center" justify="center">
      <v-card
        class="elevation-12 ma-4"
        width="400"
        style="max-width: calc(100% - 24px)"
      >
        <v-toolbar color="primary" dark flat dense>
          <v-toolbar-title class="primarytext--text">{{
            $t('Login.login')
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-img
            :src="$store.getters.siteConfig.LOGIN_LOGO"
            contain
            max-height="125"
            max-width="100%"
            class="mb-4 mx-auto"
          />

          <v-form
            ref="formLogin"
            v-model="login_valid"
            @submit.prevent="login"
            lazy-validation
          >
            <v-text-field
              required
              placeholder=" "
              persistent-placeholder
              hide-details="auto"
              v-model="login_name"
              type="name"
              v-bind:label="$t('Login.name')"
              :rules="rules_Name"
              class="mb-1"
            />

            <v-text-field
              required
              placeholder=" "
              persistent-placeholder
              hide-details="auto"
              v-model="login_password"
              :type="hidePassword ? 'password' : 'text'"
              v-bind:label="$t('Login.password')"
              :rules="rules_Password"
              @keydown.enter="login"
              :append-icon="hidePassword ? 'ci-eye_barred' : 'ci-eye'"
              @click:append="() => (hidePassword = !hidePassword)"
            />

            <div class="d-flex justify-end">
              <v-checkbox
                hide-details
                dense
                v-model="checkbox_remember"
                v-bind:label="$t('Login.remember')"
                @change="onRememberChange"
              />
            </div>

            <a
              v-if="!disableAccountManagement"
              @click="showResetPassword"
              class="text-right text-decoration-underline d-block mt-1 text-caption"
            >
              {{ $t('Login.resetPassword') }}
            </a>

            <a
              v-if="!disableAccountManagement && showChangePasswordLink"
              @click="showChangePassword"
              class="text-right text-decoration-underline d-block mt-1 text-caption"
            >
              {{ $t('Login.changePassword') }}
            </a>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            required
            :disabled="!login_valid"
            :loading="loading"
            color="secondary"
            class="ml-2 secondarytext--text px-4"
            @click="login"
          >
            {{ $t('Login.login') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

    <v-dialog
      v-model="changePassword_dialog"
      width="500"
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            {{ $t('Login.changePassword') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="changePassword_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col class="text-center" cols="12">
              <v-form
                ref="formChangePassword"
                v-model="changePassword_valid"
                @submit.prevent="changePassword"
                lazy-validation
              >
                <v-text-field
                  required
                  placeholder=" "
                  persistent-placeholder
                  class="mt-4"
                  v-model="changePassword_name"
                  type="name"
                  :rules="rules_Name"
                  v-bind:label="$t('Login.name')"
                />
                <v-text-field
                  required
                  placeholder=" "
                  persistent-placeholder
                  v-model="changePassword_currentPassword"
                  type="password"
                  :rules="rules_Password"
                  v-bind:label="$t('Login.currentPassword')"
                />
                <v-text-field
                  required
                  placeholder=" "
                  v-model="changePassword_newPassword"
                  type="password"
                  :rules="rules_Password"
                  v-bind:label="$t('Login.newPassword')"
                />
                <v-text-field
                  required
                  placeholder=" "
                  persistent-placeholder
                  v-model="changePassword_newPasswordConfirm"
                  type="password"
                  :rules="rules_ConfirmPassword"
                  v-bind:label="$t('Login.newPasswordConfirm')"
                  @keydown.enter="changePassword"
                />
                <v-btn
                  required
                  :disabled="!changePassword_valid"
                  :loading="loading"
                  color="secondary"
                  class="secondarytext--text"
                  @click="changePassword"
                >
                  <v-icon left>mdi-pen-lock</v-icon>
                  {{ $t('Login.changePassword') }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="resetPassword_dialog"
      width="500"
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            {{ $t('Login.resetPassword') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="resetPassword_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col class="text-center" cols="12">
              <v-form
                ref="formResetPassword"
                v-model="resetPassword_valid"
                @submit.prevent="resetPassword"
                lazy-validation
              >
                <v-text-field
                  required
                  placeholder=" "
                  persistent-placeholder
                  v-model="resetPassword_name"
                  type="name"
                  :rules="rules_Name"
                  v-bind:label="$t('Login.name')"
                  class="mt-4"
                />
                <v-text-field
                  required
                  persistent-placeholder
                  v-model="resetPassword_email"
                  type="email"
                  :rules="rules_Email"
                  @keydown.enter="resetPassword"
                  v-bind:label="$t('Login.email')"
                />
                <v-btn
                  required
                  :disabled="!resetPassword_valid"
                  :loading="loading"
                  color="secondary"
                  class="secondarytext--text"
                  @click="resetPassword"
                >
                  <v-icon left>mdi-lock-reset</v-icon>
                  {{ $t('Login.resetPassword') }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AuthenticationService from '../services/AuthenticationService'

export default {
  data() {
    return {
      loading: false,
      hidePassword: true,
      showChangePasswordLink: false,
      login_name: '',
      login_password: '',
      login_valid: true,
      resetPassword_name: '',
      resetPassword_email: '',
      resetPassword_valid: true,
      resetPassword_dialog: false,
      changePassword_name: '',
      changePassword_currentPassword: '',
      changePassword_newPassword: '',
      changePassword_newPasswordConfirm: '',
      changePassword_valid: true,
      changePassword_dialog: false,
      checkbox_remember: false,
    }
  },
  computed: {
    rules_Name() {
      this.$i18n.locale
      return [(v) => !!v || this.$t('Login.nameRequired')]
    },
    rules_Email() {
      this.$i18n.locale
      return [
        (v) => !!v || this.$t('Login.emailRequired'),
        (v) => /.+@.+/.test(v) || this.$t('Login.invalidEmail'),
      ]
    },
    rules_ConfirmPassword() {
      this.$i18n.locale
      return [
        (v) =>
          v == this.changePassword_newPassword ||
          this.$t('Login.passwordsDoNotMatch'),
        (v) => !!v || this.$t('Login.passwordRequired'),
      ]
    },
    rules_Password() {
      this.$i18n.locale
      return [(v) => !!v || this.$t('Login.passwordRequired')]
    },
    rules_NotEmpty() {
      return [(v) => !!v || this.$t('Login.valueRequired')]
    },
    disableAccountManagement() {
      return (
        this.$store.getters.siteConfig.DISABLE_ACCOUNTMANAGEMENT != null &&
        this.$store.getters.siteConfig.DISABLE_ACCOUNTMANAGEMENT == true
      )
    },
  },
  methods: {
    showChangePassword() {
      this.changePassword_dialog = true
      if (this.$refs.formChangePassword) {
        this.$refs.formChangePassword.reset()
      }
    },
    changePassword() {
      let inst = this
      this.changePassword_valid = this.$refs.formChangePassword.validate()
      if (this.changePassword_valid) {
        this.loading = true
        let request = {}
        request.Login = this.changePassword_name
        request.OldPassword = this.changePassword_currentPassword
        request.NewPassword = this.changePassword_newPassword
        request.DomainName = this.$store.getters.siteConfig.DOMAIN
        AuthenticationService.changePassword(request)
          .then(function () {
            inst.login_name = inst.changePassword_name
            inst.login_password = inst.changePassword_newPassword

            inst.changePassword_dialog = false
            inst.changePassword_name = inst.user
            inst.changePassword_newPassword = ''
            inst.changePassword_newPasswordConfirm = ''
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
    showResetPassword() {
      this.resetPassword_name = this.login_name
      this.resetPassword_dialog = true
      if (this.$refs.formResetPassword) {
        this.$refs.formResetPassword.reset()
      }
    },
    onRememberChange() {
      if (!this.checkbox_remember) {
        window.localStorage.removeItem(
          'eas-auth-' + this.$store.getters.siteConfig.DOMAIN
        )
      }
    },
    login() {
      let route = this.$route.query.path

      let inst = this
      this.login_valid = this.$refs.formLogin.validate()
      if (this.login_valid) {
        this.loading = true

        if (this.checkbox_remember) {
          window.localStorage.setItem(
            'eas-auth-' + this.$store.getters.siteConfig.DOMAIN,
            window.btoa(
              JSON.stringify({
                login: inst.login_name,
                password: inst.login_password,
              })
            )
          )
        }

        let name = this.login_name
        let password = this.login_password.padEnd(10, '0')
        this.$store
          .dispatch('login', { name, password })
          .then(() => {
            inst.$store.dispatch('setRoutes', inst).then(function () {
              let easGroupResult = inst.$store.getters.routes.filter(
                (q) => q.name == 'Navigation.eas'
              )
              if (route) {
                inst.$router.push(route)
                return
              }
              if (
                easGroupResult.length == 1 &&
                easGroupResult[0].children != null &&
                Array.isArray(easGroupResult[0].children) &&
                easGroupResult[0].children.length > 0
              ) {
                easGroupResult[0].children.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )
                inst.$router.push(easGroupResult[0].children[0].path)
              } else {
                inst.$router.push('/')
              }
            })
          })
          .catch((error) => {
            let msg =
              error.response?.data?.Message || error.response?.statusText || ''

            if (
              !!msg &&
              (msg.indexOf('must change password') !== -1 ||
                msg.indexOf('doit changer de mot de passe') !== -1 ||
                msg.indexOf('moet aangepast worden') !== -1)
            ) {
              inst.changePassword_name = inst.login_name
              inst.changePassword_currentPassword = inst.login_password
              inst.showChangePasswordLink = true
              inst.showChangePassword()
            }
          })
          .finally(() => (this.loading = false))
      }
    },
    copy(value) {
      const el = document.createElement('textarea')
      el.addEventListener('focusin', (e) => e.stopPropagation())
      el.value = value
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.$toast.success(this.$t('Global.copied'), {
        position: 'top-right',
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: 'v-icon mdi mdi-check',
        rtl: false,
        toastClassName: 'customToast',
      })
    },
    resetPassword() {
      let inst = this
      this.resetPassword_valid = this.$refs.formResetPassword.validate()
      if (this.resetPassword_valid) {
        this.loading = true
        let request = {}
        request.Login = this.resetPassword_name
        request.Mail = this.resetPassword_email
        request.DomainName = this.$store.getters.siteConfig.DOMAIN
        request.Link =
          window.location.origin +
          window.location.pathname +
          '#/login?tp={{password}}&un=' +
          request.Login
        AuthenticationService.resetPassword(request)
          .then(function () {
            inst.resetPassword_name = ''
            inst.resetPassword_email = ''
            inst.resetPassword_dialog = false
            inst.login_name = inst.resetPassword_name
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
  },
  mounted() {
    let tp = this.$route.query.tp
    let un = this.$route.query.un

    if (un) {
      this.login_name = un
    }

    let ls = window.localStorage.getItem(
      'eas-auth-' + this.$store.getters.siteConfig.DOMAIN
    )

    if (ls) {
      try {
        let parsed = JSON.parse(window.atob(ls))
        if (parsed) {
          this.checkbox_remember = true
          this.login_name = parsed.login
          this.login_password = parsed.password
        }
      } catch (e) {
        console.log(e)
      }
    }

    if (!!tp && !!un) {
      this.changePassword_name = un
      this.changePassword_currentPassword = tp
      this.showChangePasswordLink = true
      this.changePassword_dialog = true

      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      })
    }
  },
}
</script>
