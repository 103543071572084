const showToast = (error, inst) => {
  let msg =
    error.response?.data?.ExceptionMessage ||
    error.response?.data?.Message ||
    error.message
  inst.$toast.error(msg, {
    position: 'top-right',
    timeout: 10000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: 'v-icon mdi mdi-alert-circle',
    rtl: false,
    toastClassName: 'customToast',
  })

  if (error.response?.status === 401) {
    inst.$store.commit('logout')
    inst.$router.push('/login')
  }

  if (
    error.response != null &&
    error.response.status == 500 &&
    msg == 'Authentication ticket is expired.'
  ) {
    inst.$store.commit('logout')
    inst.$router.push('/login')
  }
}

export default showToast
