<template>
  <v-app v-resize="onResize">
    <v-app-bar app color="bars" dark dense clipped-left style="z-index: 15">
      <v-app-bar-nav-icon
        v-if="showNavigationMenu && isLoggedIn"
        @click.stop="drawer = !drawer"
        class="barstext--text"
      ></v-app-bar-nav-icon>
      <v-img
        :src="$store.getters.siteConfig.APPBAR_LOGO"
        contain
        :max-width="appbarLogoWidth"
        max-height="32px"
        class="mr-4 ml-2"
      />

      <v-toolbar-title class="barstext--text" v-if="!isMobile">{{
        $store.getters.siteConfig.TITLE
      }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y v-if="isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            class="mr-2 font-weight-medium"
            color="accent"
            text-color="accenttext"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>ci-profile_filled</v-icon>
            <span v-if="!isMobile" class="ml-2">
              {{ user }}
            </span>
          </v-chip>
        </template>
        <v-list dense>
          <v-list-item @click="logout">
            <v-list-item-action class="mr-2">
              <v-icon color="primary">ci-logout</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t('Navigation.logout') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isLoggedIn && !disableAccountManagement"
            @click="showChangePassword"
          >
            <v-list-item-action class="mr-2">
              <v-icon color="primary">ci-password_reset</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{
              $t('Login.changePassword')
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            class="font-weight-medium"
            color="accent"
            text-color="accenttext"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>ci-globe_filled</v-icon>
            <span v-if="!isMobile" class="ml-2">
              {{ $i18n.locale.toUpperCase() }}
            </span>
          </v-chip>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(lang, i) in languages"
            :key="`Lang${i}`"
            :value="lang"
            @click="setLanguage(lang)"
            class="pl-0"
          >
            <country-flag
              :country="getIsoValue(lang)"
              rounded
              class="mx-0 my-0"
            />
            <span>
              {{ lang.toUpperCase() }}
            </span>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-chip
        class="ml-2 font-weight-medium"
        color="accent"
        text-color="accenttext"
        v-if="
          $store.getters.siteConfig.TOKEN_COUNTER &&
          validUntil != '' &&
          !isMobile
        "
      >
        <v-icon class="mr-2">mdi-lock-clock</v-icon>
        {{ validUntil }}
      </v-chip>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      v-if="showNavigationMenu && isLoggedIn && configSuccess"
      app
      clipped
      class="tertiary pt-12 pt-sm-0"
    >
      <v-list dense :disabled="routes.length == 0">
        <menu-item v-for="(r, i) in routes" :key="i" :route="r"></menu-item>
        <v-list-item link v-if="!isLoggedIn" to="/login">
          <v-list-item-action>
            <v-icon>mdi-key</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Navigation.login') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="pb-0 sm:pb-9">
      <router-view />
    </v-main>

    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-title
          class="text-center primarytext--text"
          style="display: block"
        >
          {{ $t('Global.loading') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="changePassword_dialog"
      width="500"
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            {{ $t('Login.changePassword') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeChangePassword">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col class="text-center" cols="12">
              <v-form
                ref="formChangePassword"
                v-model="changePassword_valid"
                @submit.prevent="changePassword"
                lazy-validation
              >
                <v-text-field
                  class="mt-2"
                  required
                  v-model="changePassword_name"
                  type="name"
                  :rules="rules_Name"
                  v-bind:label="$t('Login.name')"
                />
                <v-text-field
                  class="mb-2"
                  required
                  v-model="changePassword_currentPassword"
                  :type="hidePassword_current ? 'password' : 'text'"
                  nofill
                  autocomplete="new-password"
                  :name="generateGuid()"
                  :rules="rules_CurrentPassword"
                  v-bind:label="$t('Login.currentPassword')"
                  v-bind:hint="$t('UserManagement.mandatoryField')"
                  persistent-hint
                  :append-icon="
                    hidePassword_current ? 'ci-eye_barred' : 'ci-eye'
                  "
                  @click:append="
                    () => (hidePassword_current = !hidePassword_current)
                  "
                />
                <v-text-field
                  class="mb-2"
                  required
                  v-model="changePassword_newPassword"
                  :type="hidePassword_new ? 'password' : 'text'"
                  nofill
                  autocomplete="new-password"
                  :name="generateGuid()"
                  :rules="rules_NewPassword"
                  v-bind:hint="$t('UserManagement.mandatoryField')"
                  v-bind:label="$t('Login.newPassword')"
                  persistent-hint
                  :append-icon="hidePassword_new ? 'ci-eye_barred' : 'ci-eye'"
                  @click:append="() => (hidePassword_new = !hidePassword_new)"
                />
                <v-text-field
                  class="mb-2"
                  required
                  v-model="changePassword_newPasswordConfirm"
                  :type="hidePassword_confirm ? 'password' : 'text'"
                  nofill
                  autocomplete="new-password"
                  :name="generateGuid()"
                  :rules="rules_ConfirmPassword"
                  v-bind:hint="$t('UserManagement.mandatoryField')"
                  v-bind:label="$t('Login.newPasswordConfirm')"
                  persistent-hint
                  @keydown.enter="changePassword"
                  :append-icon="
                    hidePassword_confirm ? 'ci-eye_barred' : 'ci-eye'
                  "
                  @click:append="
                    () => (hidePassword_confirm = !hidePassword_confirm)
                  "
                />
                <v-row class="ma-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="mt-4">
                        <v-icon class="mr-2">ci-search</v-icon>
                        <span>{{ $t('UserManagement.passwordRules') }}</span>
                      </div>
                    </template>
                    <p :class="{ 'mb-0': passwordRegexMessage == '' }">
                      {{ $t('UserManagement.passwordMinimumLength') }}:
                      {{ passwordMinLength }}
                    </p>
                    <p v-if="passwordRegexMessage != ''" class="mb-0">
                      {{ passwordRegexMessage }}
                    </p>
                  </v-tooltip>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            required
            :disabled="!changePassword_valid"
            :loading="loading"
            color="secondary"
            class="secondarytext--text mr-3 mb-2"
            @click="changePassword"
          >
            <v-icon left>ci-edit_pen</v-icon>
            {{ $t('Login.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-footer dark color="bars" app>
      <v-icon
        class="barstext--text"
        v-if="breadCrumbs.length > 2 && $store.getters.isLoggedIn"
        >mdi-map-marker-outline</v-icon
      >
      <v-breadcrumbs
        v-if="breadCrumbs.length > 2 && $store.getters.isLoggedIn"
        :items="breadCrumbs"
        style="padding: 0"
        class="ml-4 cursor_default barstext--text"
      >
        <template v-slot:divider>
          <v-icon class="barstext--text">mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
      <span class="barstext--text mr-2"> v{{ version }} </span>
      <span class="barstext--text">{{ copyright }}</span>
    </v-footer>
  </v-app>
</template>

<style>
@import './assets/fonts/ci.css';
@import './assets/style.scss';
</style>

<script>
import moment from 'moment'
import { v4 } from 'uuid'
import ApiService from './services/ApiService'
import AuthenticationService from './services/AuthenticationService'
import UserManagementService from './services/UserManagementService'
import showToast from './toasts'

export default {
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    configSuccess: function () {
      return this.$store.getters.configStatus == 'success'
    },
    routes: function () {
      return this.$store.getters.routes
    },
    user: function () {
      return this.$store.getters.user
    },
    loading: function () {
      return this.$store.getters.configStatus == 'loading'
    },
    version: function () {
      return process.env.VERSION
    },
    title: function () {
      return this.$store.getters.siteConfig.TITLE
    },
    copyright: function () {
      return this.$store.getters.siteConfig.COPYRIGHT
    },
    isMobile() {
      return this.windowSize.x <= 599
    },
    rules_ConfirmPassword() {
      this.$i18n.locale
      return [
        (v) =>
          v == this.changePassword_newPassword ||
          this.$t('Login.passwordsDoNotMatch'),
        (v) => !!v || this.$t('Login.passwordRequired'),
      ]
    },
    rules_CurrentPassword() {
      this.$i18n.locale
      return [(v) => !!v || this.$t('Login.passwordRequired')]
    },
    rules_NewPassword() {
      this.$i18n.locale
      let result = []
      let inst = this

      if (this.passwordRegex != '') {
        let isValid = true
        try {
          new RegExp(this.passwordRegex)
        } catch (e) {
          isValid = false
        }

        if (isValid) {
          let regex = new RegExp(this.passwordRegex)

          result = [
            (v) => !!v || this.$t('Login.passwordRequired'),
            (v) =>
              (!!v && v.length >= inst.passwordMinLength) ||
              this.$t('Login.passwordTooShort'),
            (v) => (!!v && regex.test(v)) || this.passwordRegexMessage,
          ]
        } else {
          result = [
            (v) => !!v || this.$t('Login.passwordRequired'),
            (v) =>
              (!!v && v.length >= inst.passwordMinLength) ||
              this.$t('Login.passwordTooShort'),
          ]
        }
      } else {
        result = [
          (v) => !!v || this.$t('Login.passwordRequired'),
          (v) =>
            (!!v && v.length >= inst.passwordMinLength) ||
            this.$t('Login.passwordTooShort'),
        ]
      }

      return result
    },
    rules_Name() {
      this.$i18n.locale
      return [(v) => !!v || this.$t('Login.nameRequired')]
    },
    disableAccountManagement() {
      return (
        this.$store.getters.siteConfig.DISABLE_ACCOUNTMANAGEMENT != null &&
        this.$store.getters.siteConfig.DISABLE_ACCOUNTMANAGEMENT == true
      )
    },
    showNavigationMenu() {
      let result = true

      let count = this.routes.filter(
        (q) =>
          (q.name != 'Navigation.home' &&
            q.children &&
            q.children.length > 0) ||
          (!q.children && q.meta && q.meta.visible)
      ).length

      if (
        this.$store.getters.siteConfig.COLLAPSE_NAVIGATION_MENU &&
        count == 1 &&
        this.$route.path.includes('/EAS/')
      ) {
        result = false
      }

      return result
    },
  },
  data: () => ({
    drawer: true,
    dark: false,
    validUntil: '',
    languages: [],
    breadCrumbs: [],
    appbarLogoWidth: '',
    windowSize: {},
    changePassword_name: '',
    changePassword_currentPassword: '',
    changePassword_newPassword: '',
    changePassword_newPasswordConfirm: '',
    changePassword_valid: true,
    changePassword_dialog: false,
    hidePassword_current: true,
    hidePassword_new: true,
    hidePassword_confirm: true,
    passwordMinLength: 6,
    passwordRegex: '',
    passwordRegexMessage: '',
  }),
  head: {
    title: function () {
      return {
        inner: this.title,
        separator: ' ',
      }
    },
    meta: function () {
      return [{ name: 'version', content: this.version }]
    },
  },
  watch: {
    $route() {
      this.getBreadCrumbs()
    },
  },
  components: {
    MenuItem: () => import('./components/MenuItem'),
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    },
    generateGuid() {
      return v4()
    },
    showChangePassword() {
      let inst = this

      this.getPasswordRules().then(() => {
        inst.changePassword_name = this.user
        inst.changePassword_dialog = true
      })
    },
    changePassword() {
      let inst = this
      this.changePassword_valid = this.$refs.formChangePassword.validate()
      if (this.changePassword_valid) {
        this.loading = true
        let request = {}
        request.Login = this.changePassword_name
        request.OldPassword = this.changePassword_currentPassword
        request.NewPassword = this.changePassword_newPassword
        request.DomainName = this.$store.getters.siteConfig.DOMAIN
        AuthenticationService.changePassword(request)
          .then(function () {
            inst.closeChangePassword()
          })
          .finally(function () {
            inst.loading = false
          })
      }
    },
    getPasswordRules() {
      let inst = this

      let request = {}

      request.Domain = this.$store.getters.siteConfig.DOMAIN
      request.Issuer = 'Built-In'

      this.loading = true

      return new Promise((resolve, reject) => {
        UserManagementService.getPasswordRules(request)
          .then((response) => {
            inst.passwordMinLength = response.data.MinimumLength
            inst.passwordRegex = response.data.Regex
            inst.passwordRegexMessage = response.data.RegexMessage
            resolve()
          })
          .catch(function (error) {
            reject(error)
          })
          .finally(function () {
            inst.loading = false
          })
      })
    },
    closeChangePassword() {
      this.$refs.formChangePassword.reset()
      this.changePassword_dialog = false
    },
    setLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.dispatch('setLanguage', lang)
      this.$vuetify.lang.current = lang
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    getBreadCrumbs() {
      let items = []
      let routeComponents = this.$router.currentRoute.fullPath.split('/')

      if (routeComponents.length == 3) {
        let routeName = this.$t(
          'Navigation.' + routeComponents[1] + '_children.' + this.$route.name
        )
        if (routeComponents[1] == 'EAS') {
          routeName = this.$route.params.id
        }
        items.push({ text: this.$t('Navigation.' + routeComponents[1]) })
        items.push({ text: routeName })
      }

      if (routeComponents.length == 2) {
        items.push({ text: this.$t(this.$route.name) })
      }

      this.breadCrumbs = items
    },
    getIsoValue(lang) {
      switch (lang) {
        case 'en':
          return 'gb'
        default:
          return lang
      }
    },
    checkTokenValidity() {
      if (this.$store.getters.isLoggedIn) {
        let validUntil = JSON.parse(this.$store.getters.token).ValidUntil
        let now = moment.utc()
        let parsedDate = moment(validUntil)
        let seconds = parsedDate.diff(now, 'seconds')
        let difference = new Date(seconds * 1000).toISOString().substr(11, 8)
        this.validUntil = difference

        if (seconds < 0) {
          this.logout()
        }
      } else {
        this.validUntil = ''
      }
    },
    getAppbarLogoWidth() {
      let url =
        window.location.origin +
        window.location.pathname +
        this.$store.getters.siteConfig.APPBAR_LOGO
      let inst = this

      var img = new Image()
      img.src = url
      img.onload = function () {
        inst.appbarLogoWidth = (this.width / this.height) * 32
      }
    },
  },
  created() {
    this.getBreadCrumbs()

    this.$vuetify.theme.themes.dark.primary = 'primary'

    let inst = this

    this.$http.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        showToast(error, inst)

        return Promise.reject(error)
      }
    )
  },
  mounted() {
    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.I18N_FALLBACK_LOCALE != null
    ) {
      this.$i18n.fallbackLocale =
        this.$store.getters.siteConfig.I18N_FALLBACK_LOCALE
    } else {
      this.$i18n.fallbackLocale = 'en'
    }

    if (this.$store.getters.lang != undefined) {
      this.$i18n.locale = this.$store.getters.lang
    } else {
      if (
        this.$store.getters.siteConfig != null &&
        this.$store.getters.siteConfig.I18N_LOCALE != null
      ) {
        this.$i18n.locale = this.$store.getters.siteConfig.I18N_LOCALE
      } else {
        this.$i18n.locale = this.$i18n.fallbackLocale
      }
    }

    this.$store.dispatch('setLanguage', this.$i18n.locale)
    this.$vuetify.lang.current = this.$i18n.locale

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_PRIMARY_BG != null
    ) {
      this.$vuetify.theme.currentTheme.primary =
        this.$store.getters.siteConfig.COLOR_PRIMARY_BG
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_PRIMARY_TEXT != null
    ) {
      this.$vuetify.theme.currentTheme.primarytext =
        this.$store.getters.siteConfig.COLOR_PRIMARY_TEXT
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_SECONDARY_BG != null
    ) {
      this.$vuetify.theme.currentTheme.secondary =
        this.$store.getters.siteConfig.COLOR_SECONDARY_BG
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_SECONDARY_TEXT != null
    ) {
      this.$vuetify.theme.currentTheme.secondarytext =
        this.$store.getters.siteConfig.COLOR_SECONDARY_TEXT
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_TERTIARY_BG != null
    ) {
      this.$vuetify.theme.currentTheme.tertiary =
        this.$store.getters.siteConfig.COLOR_TERTIARY_BG
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_TERTIARY_TEXT != null
    ) {
      this.$vuetify.theme.currentTheme.tertiarytext =
        this.$store.getters.siteConfig.COLOR_TERTIARY_TEXT
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_BARS_BG != null
    ) {
      this.$vuetify.theme.currentTheme.bars =
        this.$store.getters.siteConfig.COLOR_BARS_BG
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_BARS_TEXT != null
    ) {
      this.$vuetify.theme.currentTheme.barstext =
        this.$store.getters.siteConfig.COLOR_BARS_TEXT
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_ACCENT_BG != null
    ) {
      this.$vuetify.theme.currentTheme.accent =
        this.$store.getters.siteConfig.COLOR_ACCENT_BG
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_ACCENT_TEXT != null
    ) {
      this.$vuetify.theme.currentTheme.accenttext =
        this.$store.getters.siteConfig.COLOR_ACCENT_TEXT
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_DOCFLOWTASKBG != null
    ) {
      this.$vuetify.theme.currentTheme.docflowtaskbg =
        this.$store.getters.siteConfig.COLOR_DOCFLOWTASKBG
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_SUCCESS != null
    ) {
      this.$vuetify.theme.currentTheme.success =
        this.$store.getters.siteConfig.COLOR_SUCCESS
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_SUCCESS != null
    ) {
      this.$vuetify.theme.currentTheme.warning =
        this.$store.getters.siteConfig.COLOR_WARNING
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_SUCCESS != null
    ) {
      this.$vuetify.theme.currentTheme.info =
        this.$store.getters.siteConfig.COLOR_INFO
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.COLOR_SUCCESS != null
    ) {
      this.$vuetify.theme.currentTheme.error =
        this.$store.getters.siteConfig.COLOR_ERROR
    }

    if (
      this.$store.getters.siteConfig != null &&
      this.$store.getters.siteConfig.LANGUAGES != null
    ) {
      this.languages = this.$store.getters.siteConfig.LANGUAGES
    }

    if (this.$store.getters.isLoggedIn) {
      ApiService.setHeader(this.$store.getters.token)
    }

    this.drawer =
      this.$store.getters.siteConfig.NAVIGATION_MENU_DEFAULT == false
        ? false
        : true

    this.checkTokenValidity()

    this.getAppbarLogoWidth()

    setInterval(this.checkTokenValidity, 1000)
  },
}
</script>
