import moment from 'moment'
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index.js'
import NotFound from '../views/404.vue'
import AuthorizationAclView from '../views/AuthorizationAcl.vue'
import AuthorizationConstraintsView from '../views/AuthorizationConstraints.vue'
import DocflowView from '../views/Docflow.vue'
import EasView from '../views/Eas.vue'
import Landing from '../views/Landing.vue'
import Login from '../views/Login.vue'
import Root from '../views/Root.vue'
import SsoView from '../views/SSO.vue'
import StatisticsView from '../views/Statistics.vue'
import TraceabilityAttestationsView from '../views/TraceabilityAttestations.vue'
import TraceabilityAuditView from '../views/TraceabilityAudit.vue'
import TraceabilityConformityView from '../views/TraceabilityConformity.vue'
import UserManagementView from '../views/UserManagement.vue'
import UserManagementAuditView from '../views/UserManagementAudit.vue'

Vue.use(Router)

let router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'Root',
      component: Root,
      meta: {
        requiresAuth: true,
        visible: false,
      },
    },
    {
      path: '/home',
      name: 'Navigation.home',
      component: Landing,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'Navigation.login',
      component: Login,
    },
    {
      path: '/EAS/:id',
      component: EasView,
      meta: {
        requiresAuth: true,
        visible: false,
        parent: 'Navigation.eas',
      },
    },
    {
      path: '/SSO/:ticket',
      component: SsoView,
      meta: {
        requiresAuth: false,
        visible: false,
      },
    },
    {
      path: '/SSO',
      component: SsoView,
      meta: {
        requiresAuth: false,
        visible: false,
      },
    },
    {
      path: '/admin/statistics',
      component: StatisticsView,
      name: 'Statistics',
      meta: {
        requiresAuth: true,
        visible: false,
        parent: 'Navigation.admin',
      },
    },
    {
      path: '/admin/audit',
      component: TraceabilityAuditView,
      name: 'TraceabilityAudit',
      meta: {
        requiresAuth: true,
        visible: false,
        parent: 'Navigation.admin',
      },
    },
    {
      path: '/admin/attestations',
      component: TraceabilityAttestationsView,
      name: 'Attestations',
      meta: {
        requiresAuth: true,
        visible: false,
        parent: 'Navigation.admin',
      },
    },
    {
      path: '/admin/conformity',
      component: TraceabilityConformityView,
      name: 'Conformity',
      meta: {
        requiresAuth: true,
        visible: false,
        parent: 'Navigation.admin',
      },
    },
    {
      path: '/usermanagement/acl',
      component: AuthorizationAclView,
      name: 'Authorization',
      meta: {
        requiresAuth: true,
        visible: false,
        parent: 'Navigation.usermanagement',
      },
    },
    {
      path: '/admin/constraints',
      component: AuthorizationConstraintsView,
      name: 'Constraints',
      meta: {
        requiresAuth: true,
        visible: false,
        parent: 'Navigation.admin',
      },
    },
    {
      path: '/usermanagement/audit',
      component: UserManagementAuditView,
      name: 'UserManagementAudit',
      meta: {
        requiresAuth: true,
        visible: false,
        parent: 'Navigation.usermanagement',
      },
    },
    {
      path: '/usermanagement/users',
      component: UserManagementView,
      name: 'Users',
      meta: {
        requiresAuth: true,
        visible: false,
        parent: 'Navigation.usermanagement',
      },
    },
    {
      path: '/docflow',
      component: DocflowView,
      name: 'Navigation.docflow',
      meta: {
        requiresAuth: true,
        visible: false,
      },
    },
    {
      path: '*',
      component: NotFound,
      meta: {
        requiresAuth: true,
        visible: false,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (store.getters.messages == undefined) {
    store.dispatch('setLanguages', router.app)
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      let path = to.path

      if (!!to.query && Object.entries(to.query).length > 0) {
        path = path + '?'
        let i = 0
        for (const property in to.query) {
          if (i != 0) {
            path = path + '&'
          }
          path =
            path +
            encodeURIComponent(property) +
            '=' +
            encodeURIComponent(to.query[property])
          i++
        }
      }

      if (path && path != '/') {
        next('/login?path=' + encodeURIComponent(path))
      } else {
        next('/login')
      }
    } else {
      if (store.getters.configStatus != 'success') {
        let validUntil = JSON.parse(store.getters.token).ValidUntil
        let now = moment()
        let parsedDate = moment(validUntil)
        let seconds = parsedDate.diff(now, 'seconds')

        if (seconds < 0) {
          store.dispatch('logout').then(() => {
            next('/login')
          })
        } else {
          store.dispatch('setRoutes', router.app).then(function () {
            next()
          })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
