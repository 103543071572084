import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'
import en from './locales/en.json'
import fr from './locales/fr.json'
import nl from './locales/nl.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: store.getters.siteConfig.I18N_LOCALE || 'en',
  fallbackLocale: store.getters.siteConfig.I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: en,
    fr: fr,
    nl: nl,
  },
  silentTranslationWarn: true,
})
